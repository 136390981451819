<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-login-sub">
    <!-- main-header -->
    <div class="main-header">
      <!-- main-header > header-top -->
      <div class="header-top main-component">
        <div class="header-column">
          <router-link :to="{name: 'Signin'}" href="" class="page-nav">
            <i class="icon-kb-nav-arrow"></i>
            <span class="text">이전</span>
          </router-link>
        </div>
<!--        <div v-if="isAuthentication" class="header-column">-->
<!--          <button class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="downloadApp(`https://ehrd.kbstar.com/pr/kbh_app.proc?m_os=ios&tkey=${info.lrnerId}&fkey=1`)"><i class="icon-ios icon-appdown"></i><span class="text">아이폰 앱</span></button>-->
<!--          <button class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg"  @click="downloadApp(`https://ehrd.kbstar.com/pr/kbh_app.proc?m_os=and&tkey=${info.lrnerId}&fkey=1`)"><i class="icon-android icon-appdown"></i><span class="text">안드로이드 앱</span></button>-->
<!--        </div>-->
      </div>
      <!-- main-header > header-title -->
      <div class="header-title">
        <h3 class="title mt-5">KB스타런 앱 다운로드</h3>
      </div>
    </div>
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content min-component">
      <div class="m-3">
        <!-- 직원 인증 후 -->
        <template v-if="isAuthentication">
          <section class="content-section section-forms">
            <header class="section-header">
              <h2 class="title">◎ 개인정보 확인</h2>
            </header>
            <div class="kb-form-fields mt-5">
                <span class="kb-form-label-text fs-6">{{ userInfo.lrnerNm }} / {{ userInfo.deptNm }} ( {{ userInfo.lrnerId}} )</span>
            </div>
          </section>
          <section class="content-section section-forms section-divider">

            <div class="header-column mt-3 text-center">
              <button class="kb-btn kb-btn-warning kb-btn-sm rounded-lg me-3 " @click="clickAppInstallGuide('IOS')"><i class="icon-ios icon-appdown"></i><span class="text">iOS</span></button>
              <button class="kb-btn kb-btn-warning kb-btn-sm rounded-lg"  @click="clickAppInstallGuide('Android')"><i class="icon-android icon-appdown"></i><span class="text">안드로이드</span></button>
            </div>

            <AppInstallGuide v-model:is-show="isAppInstallShow" :app-type="appType" :app-url="appDownloadUrl" />

          </section>
        </template>
        <!-- 직원 인증 전 -->
        <template v-else>
          <section class="content-section section-forms">
            <header class="section-header">
              <h2 class="title">개인정보 확인</h2>
            </header>
            <div class="kb-form-fields">
              <div class="auth-forms">
                <div class="field-area">
                  <div class="kb-form-input-group">
                    <input autocomplete="username" type="text" class="kb-form-whiteinput" placeholder="직원번호를 입력하세요"
                           v-model.trim="info.lrnerId" @keyup.enter="enterBirth"
                    >
                    <div class="input-box" style="border-color: black; background-color: white;"></div>
                  </div>
                </div>
                <div class="field-area">
                  <div class="kb-form-input-group">
                    <input autocomplete="username" type="text" class="kb-form-whiteinput" placeholder="생년월일 6자리를 입력하세요 (YYMMDD)"
                           v-model.trim="info.brdt" ref="birthDt" maxlength="6" @keyup.enter="clickIdentification"
                    >
                    <div class="input-box" style="border-color: black; background-color: white;"></div>
                  </div>
                </div>
              </div>
              <div class="kb-form-buttons">
                <button class="kb-btn kb-btn-primary kb-btn-lg" @click="clickIdentification"><span class="text">본인확인</span></button>
              </div>
            </div>
          </section>
          <!-- 미사용 안내 -->
          <section v-if="false" class="content-section section-forms section-divider">
            <div>
              <header class="section-header">
                <h4 class="title">eHRD Apps 설치 유의사항</h4>
              </header>
              <div class="apps-guide" style="margin-right: 2em;">
                <p>리브똑똑/SMS 또는 e-mail은 인사시스템 직원정보에 등록된 핸드폰 혹은 이메일 주소로 인증번호가 전달됩니다.</p>
                <div class="app-guide" style="margin-top:1em;" @click="clickAppInstallGuide('Android')"><a href="javascript:">☞ 안드로이드 설치 가이드</a></div>
                <div class="app-guide" style="margin-top:1em;" @click="clickAppInstallGuide('IOS')"><a href="javascript:">☞ 아이폰(IOS) 설치 가이드 </a></div>
              </div>
            </div>
          </section>
        </template>
      </div>
    </div>
    <!-- //main-content -->


  </main>
  <!-- end::kb-main -->
</template>

<script>
import {reactive, ref} from 'vue';
import {ACT_GET_AUTH_BY_BRDT} from '@/store/modules/auth/auth';
import {getItem, lengthCheck, setParams, winOpen} from '@/assets/js/util';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import AppInstallGuide from '@/components/common/AppInstallGuidePopup';

export default {
  name: 'EhrdAppInstall',
  components: {AppInstallGuide},
  setup() {
    const store = useStore();

    const {showMessage} = useAlert();

    const isAppInstallShow = ref(false);
    const appType = ref('');
    const appDownloadUrl = ref('');
    let appsInfo, iosUrl, andUrl;

    const info = reactive({
      lrnerId: '',
      brdt: '',
      reqtype: 'appinstall'
    });

    const userInfo = reactive({
      lrnerNm : '',
      lrnerId : '',
      brdt : '',
      mblTelno : '',
      emlAddr : '',
      deptNm : '',
    });

    const isAuthentication = ref(false);

    const clickIdentification = () => {
      let result = true;

      if(!info.lrnerId){
        showMessage({
          title: '본인확인',
          text: '직원번호를 입력해주세요.'
        });
        result = false;
      } else if(info.brdt.length < 6) {
        showMessage({
          title: '본인확인',
          text: '생년월일은 6자리 숫자로 입력하셔야 합니다.'
        });
        result = false;
      }

      if(result) {
        store.dispatch(`auth/${ACT_GET_AUTH_BY_BRDT}`, info).then(res => {
          if(lengthCheck(res)) {
            setParams(userInfo,  getItem(res));
            isAuthentication.value = true;

            appsInfo = res.appsinfo;

            for(let i=0; i<appsInfo.length; i++){
              if(appsInfo[i].flatform=="AND") andUrl = appsInfo[i].downurl;
              if(appsInfo[i].flatform=="IOS") iosUrl = appsInfo[i].downurl;
            }
            // appDownloadUrl

          } else {
            showMessage({
              title: '본인확인',
              text: '입력하신 정보가 일치하지 않습니다.<br /> 다시 확인 후 입력해주세요'
            });

          }
        }).catch(e => {
          console.error(e);
        })
      }
    };

    const downloadApp = (url) => {
      winOpen(url);
    }

    const clickAppInstallGuide = (changeAppType) => {
      if(confirm("안드로이드 운영체제 버전이 9 미만일 경우 설치가 불가합니다.\n" + "다운로드를 진행하시겠습니까?")) {
        appType.value = changeAppType;
        appDownloadUrl.value = changeAppType == "IOS" ? `itms-services://?action=download-manifest&url=${iosUrl}` : andUrl;
        isAppInstallShow.value = true;
        window.location.href = appDownloadUrl.value;
      }
    }


    return {
      isAuthentication,
      info,
      userInfo,
      isAppInstallShow,
      appType,
      appDownloadUrl,

      downloadApp,
      clickIdentification,
      clickAppInstallGuide
    }
  }
  ,methods:{
    enterBirth: function(){
      this.$refs.birthDt.focus();
    }
  }
};
</script>
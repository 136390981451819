<template>
  <!-- begin:popup-container -->
  <div v-show="isShow" >
    <!-- popup -->


            <div class="mb-6">
              <!-- 설치안내 -->
              <section class="popup-section">
                <header class="section-header">
                  <h4 class="title">설치안내</h4>
                </header>
                <div v-if="appType === 'Android'" class="segment-box p-3">
                  <p class="text">설치파일 다운로드가 시작되었습니다. 다운로드가 완료되면 아래 순서로 진행하세요</p>
                  <p class="text" style="font-size: 14px;">※ 안드로이드 버전이 낮은 경우 설치가 되지 않을 수 있습니다. 최신 기기에서 설치 바랍니다. (최소 버전 9 이상)</p>
                </div>

                <div v-else-if="appType === 'IOS'" class="segment-box p-3">
                  <p class="text">[열기] → [설치]를 누르시면 앱이 설치됩니다.</p>
                  <p class="text">잠시후 홈화면의 스타런 앱 설치여부를 확인하세요.</p><br>
                  <p class="text">[설정]→[일반]→[기기관리]→[Kookmin Bank] 에서 <span class="auth-text">Kookmin Bank을(를)신뢰함</span> 을 선택해주세요</p><br>
                </div>

              </section>
              <!-- 설치절차 -->
              <section v-if="appType === 'Android'" class="popup-section">
                <header class="section-header">
                  <h4 class="title">설치절차</h4>
                </header>
                <div class="segment-box p-3">
                  <p class="text">1. [열기] 버튼 클릭</p>
                  <p class="text">2. 보안관련 팝업 나올 시 [설정] 버튼 클릭</p>
                  <p class="text">3. 하단 [출처를 알 수 없는 앱] 설정을 눌러 나타나는 팝업에서 [허용] 버튼을 눌러 계속 진행</p>
                  <p class="text ms-3">(팝업에 있는 [이번에만 설치 허용] 버튼은 on/off 상관 없음)</p>
                  <p class="text">4. 다음 나타나는 설치 화면에서 [설치] 버튼을 눌러 설치 실행</p>
                  <p class="text">5. [열기] 버튼을 눌러 어플 실행</p>
                  <p class="text">6. 초기 화면에 나타나는 디바이스 기능 관련 팝업이 나타나면 [허용] 버튼을 선택</p>
                </div>
              </section>
              <!-- 설치 유의사항 -->
              <section class="popup-section">
                <header class="section-header">
                  <h4 class="title">설치 유의사항</h4>
                </header>
                <a @click="downloadSetup"><p class="subtext guide-text">{{ appTypeText }} 설치가이드</p></a>
              </section>
            </div>


    <!-- //popup -->
  </div>
  <!-- end:popup-container -->
</template>
<style>
  .segment-box > p {line-height: 1.7 !important;}
</style>
<script>
import {computed} from 'vue';
import {winOpen} from "@/assets/js/util";
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';

export default {
  name: 'AppInstallGuide',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    components: {LxpMobileHeader},
    appType: String
  },
  emits: ['update:isShow'],
  setup(props, {emit}) {


    const appTypeText = computed(()=> {

      if(props.appType === 'IOS') {
        return '아이폰(IOS)';
      } else if(props.appType === 'Android') {
        return '안드로이드';
      }

      return '';
    });

    const clickPopupClose = () => {
      emit('update:isShow', false);
    }

    const downloadSetup = () => {
      if(props.appType === 'IOS') {
        return winOpen(`${process.env.VUE_APP_CLOUDFRONT_URL}/contents/guide/ios_guide.pdf`);
      } else if(props.appType === 'Android') {
        return winOpen(`${process.env.VUE_APP_CLOUDFRONT_URL}/contents/guide/and_guide.pdf`);
      }
    }


    return {
      appTypeText,
      downloadSetup,
      clickPopupClose
    }
  }
};
</script>